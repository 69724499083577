var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
const DEFAULT_ATTRIBUTES = {
  role: "img",
  "aria-hidden": "true"
};
export default function icon(iconKey, attributes = {}) {
  const iconAttributes = __spreadValues(__spreadValues({}, DEFAULT_ATTRIBUTES), attributes);
  const htmlAttributes = { width: "0.75em", height: "0.75em" };
  Object.keys(iconAttributes).forEach((key) => {
    const newKey = key.replace(/([A-Z])/g, (ucw) => `-${ucw[0].toLowerCase()}`);
    if (typeof htmlAttributes[key] === "undefined") {
      htmlAttributes[newKey] = iconAttributes[key];
    } else {
      htmlAttributes[newKey] = `${htmlAttributes[newKey]} ${iconAttributes[key]}`;
    }
  });
  const svg = document.createElement("svg");
  const use = document.createElement("use");
  const title = document.createElement("title");
  title.innerHTML = iconAttributes.title || iconAttributes.ariaLabel || iconKey;
  use.setAttribute("href", `${window.Decidim.config.get("icons_path")}#ri-${iconKey}`);
  Object.entries(htmlAttributes).forEach(([key, value]) => svg.setAttribute(key, value));
  svg.appendChild(title);
  svg.appendChild(use);
  return svg.outerHTML;
}
