var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
export const getMessages = (key = null) => {
  const allMessages = window.Decidim.config.get("messages");
  if (key === null) {
    return allMessages;
  }
  let messages = allMessages;
  key.split(".").forEach((part) => messages = messages[part] || {});
  return messages;
};
export const createDictionary = (messages, prefix = "") => {
  let final = {};
  Object.keys(messages).forEach((key) => {
    if (typeof messages[key] === "object") {
      final = __spreadValues(__spreadValues({}, final), createDictionary(messages[key], `${prefix}${key}.`));
    } else if (key === "") {
      final[(prefix == null ? void 0 : prefix.replace(/\.$/, "")) || ""] = messages[key];
    } else {
      final[`${prefix}${key}`] = messages[key];
    }
  });
  return final;
};
export const getDictionary = (key) => {
  return createDictionary(getMessages(key));
};
