var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
window.addEventListener("load", () => __async(this, null, function* () {
  if ("serviceWorker" in navigator) {
    yield navigator.serviceWorker.register("/sw.js", { scope: "/" });
    const mandatoryElements = document.querySelector(".js-sw-mandatory");
    const isOperaMini = (navigator.userAgent.indexOf("OPR") > -1 || navigator.userAgent.indexOf("Opera") > -1) && navigator.userAgent.indexOf("Mini") > -1;
    if (mandatoryElements && isOperaMini) {
      mandatoryElements.classList.add("hide");
    }
  } else {
    console.log("Your browser does not support service workers \u{1F937}\u200D\u2640\uFE0F");
  }
}));
