export default function backToListLink(links) {
  if (!links) {
    return;
  }
  if (!window.sessionStorage) {
    return;
  }
  const filteredParams = JSON.parse(sessionStorage.getItem("filteredParams")) || {};
  links.forEach((link) => {
    const href = link.getAttribute("href");
    if (filteredParams[href]) {
      link.setAttribute("href", filteredParams[href]);
    }
  });
}
