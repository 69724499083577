var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
const visitedPages = JSON.parse(sessionStorage.getItem("visitedPages")) || [];
const DELAYED_VISITS = 2;
let deferredPrompt = null;
const shouldCountVisitedPages = () => sessionStorage.getItem("userChoice") !== "dismissed" && visitedPages.length < DELAYED_VISITS && !visitedPages.includes(location.pathname);
const shouldPrompt = () => {
  if (localStorage.getItem("pwaInstallPromptSeen")) {
    return false;
  }
  return deferredPrompt && sessionStorage.getItem("userChoice") !== "dismissed" && visitedPages.length >= DELAYED_VISITS;
};
window.addEventListener("beforeinstallprompt", (event) => {
  event.preventDefault();
  deferredPrompt = event;
  if (shouldCountVisitedPages()) {
    sessionStorage.setItem("visitedPages", JSON.stringify([...visitedPages, location.pathname]));
  }
});
window.addEventListener("click", (event) => __async(this, null, function* () {
  if (shouldPrompt()) {
    event.preventDefault();
    deferredPrompt.prompt();
    const { outcome } = yield deferredPrompt.userChoice;
    sessionStorage.setItem("userChoice", outcome);
    sessionStorage.removeItem("visitedPages");
    localStorage.setItem("pwaInstallPromptSeen", true);
  }
}));
