let callbacks = {};
export default function registerCallback(callbackId, callback) {
  callbacks[callbackId] = callback;
}
const unregisterCallback = (callbackId) => {
  callbacks[callbackId] = null;
};
const pushState = (url, state2 = null) => {
  if (window.history) {
    window.history.pushState(state2, null, url);
  }
};
const replaceState = (url, state2 = null) => {
  if (window.history) {
    window.history.replaceState(state2, null, url);
  }
};
const state = () => {
  if (window.history) {
    return window.history.state;
  }
  return null;
};
window.onpopstate = (event) => {
  if (event.isTrusted) {
    for (let callbackId in callbacks) {
      if (callbacks.hasOwnProperty(callbackId)) {
        callbacks[callbackId](event.state);
      }
    }
  }
};
export { registerCallback, unregisterCallback, pushState, replaceState, state };
