export default function delayed(context, func, wait) {
  let timeout = null;
  return function(...args) {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      timeout = null;
      Reflect.apply(func, context, args);
    }, wait);
  };
}
