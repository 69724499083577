export default function createToggle(component) {
  const { toggle } = component.dataset;
  if (!component.id) {
    component.id = `toggle-${Math.random().toString(36).substring(7)}`;
  }
  component.setAttribute("aria-controls", toggle);
  toggle.split(" ").forEach((id) => {
    const node = document.getElementById(id);
    if (node) {
      node.setAttribute("aria-labelledby", [node.getAttribute("aria-labelledby"), component.id].filter(Boolean).join(" "));
    }
  });
  component.addEventListener("click", () => {
    toggle.split(" ").forEach((id) => {
      const node = document.getElementById(id);
      if (node) {
        node.hidden = !node.hidden;
        node.setAttribute("aria-expanded", !node.hidden);
      }
    });
    document.dispatchEvent(new Event("on:toggle"));
  });
}
