$(() => {
  const removeUrlParameter = (url, parameter) => {
    const urlParts = url.split("?");
    if (urlParts.length >= 2) {
      const urlBase = urlParts.shift();
      const queryString = urlParts.join("?");
      const prefix = `${encodeURIComponent(parameter)}=`;
      const parts = queryString.split(/[&;]/g);
      for (let index = parts.length - 1; index >= 0; index -= 1) {
        if (parts[index].lastIndexOf(prefix, 0) !== -1) {
          parts.splice(index, 1);
        }
      }
      if (parts.length === 0) {
        return urlBase;
      }
      return `${urlBase}?${parts.join("&")}`;
    }
    return url;
  };
  $(document).on("click.zf.trigger", (event) => {
    const target = `#${$(event.target).data("dialogOpen")}`;
    const redirectUrl = $(event.target).data("redirectUrl");
    if (!target || !redirectUrl) {
      return;
    }
    $("<input type='hidden' />").attr("id", "redirect_url").attr("name", "redirect_url").attr("value", redirectUrl).appendTo(`${target} form`);
    $(`${target} a`).attr("href", (index, href) => {
      const querystring = jQuery.param({ "redirect_url": redirectUrl });
      return href + (href.match(/\?/) ? "&" : "?") + querystring;
    });
  });
  $(document).on("closed.zf.reveal", (event) => {
    $("#redirect_url", event.target).remove();
    $("a", event.target).attr("href", (index, href) => {
      if (href && href.indexOf("redirect_url") !== -1) {
        return removeUrlParameter(href, "redirect_url");
      }
      return href;
    });
  });
});
